// Constants color file

// VARIABLES
$black-color: #000000;
$white-color: #ffffff;

$lime-green-color: #23bf44;
$flamingo-color: #ea5151;
$peach-yellow-color: #fbddb1;
$ochre-color: #b8741e;
$misty-rose-color: #ffd8d8;
$cloud-burst-color: #363f4f;
$sunset-orange-color: #f54947;
$harlequin-color: #37cb00;
$amaranth-color: #ed3561;
$white-smoke-color: #f8f8f8;
$lime-green-color: #32c92f;
$dodger-blue-color: #3d84fd;
$star-dust-color: #6a7279;
$steel-gray-color: #e2e2e2;
$napoli-blue-color: #3d6afd;
$midnight-color: #28292c;
$middle-gray-color: #656565;
$star-dust-grey: #9c9faa;
$dark-grey: #44454a;
$ocean-green: #87eec9;

//Lime Green
.bg-lime-green-color {
  background-color: $lime-green-color;
}
.lime-green-color {
  color: $lime-green-color;
}
.b-lime-green-color {
  border-color: $lime-green-color;
}

//Flamingo
.bg-flamingo-color {
  background-color: $flamingo-color;
}
.flamingo-color {
  color: $flamingo-color;
}
.b-flamingo-color {
  border-color: $flamingo-color;
}

//Peach Yellow
.bg-peach-yellow-color {
  background-color: $peach-yellow-color;
}
.peach-yellow-color {
  color: $peach-yellow-color;
}
.b-peach-yellow-color {
  border-color: $peach-yellow-color;
}

// Ochre
.bg-ochre-color {
  background-color: $ochre-color;
}
.ochre-color {
  color: $ochre-color;
}
.b-ochre-color {
  border-color: $ochre-color;
}

//Misty Rose
.bg-misty-rose-color {
  background-color: $misty-rose-color;
}
.misty-rose-color {
  color: $misty-rose-color;
}
.b-misty-rose-color {
  border-color: $misty-rose-color;
}

//Cloud Burst
.bg-cloud-burst-color {
  background-color: $cloud-burst-color;
}
.cloud-burst-color {
  color: $cloud-burst-color;
}
.b-cloud-burst-color {
  border-color: $cloud-burst-color;
}

//Sunset Orange
.bg-sunset-orange-color {
  background-color: $sunset-orange-color;
}
.sunset-orange-color {
  color: $sunset-orange-color;
}
.b-sunset-orange-color {
  border-color: $sunset-orange-color;
}

//Harlequin
.bg-harlequin-color {
  background-color: $harlequin-color;
}
.harlequin-color {
  color: $harlequin-color;
}
.b-harlequin-color {
  border-color: $harlequin-color;
}

//Amaranth
.bg-amaranth-color {
  background-color: $amaranth-color;
}
.amaranth-color {
  color: $amaranth-color;
}
.b-amaranth-color {
  border-color: $amaranth-color;
}

//White Smoke
.bg-white-smoke-color {
  background-color: $white-smoke-color;
}
.white-smoke-color {
  color: $white-smoke-color;
}
.b-white-smoke-color {
  border-color: $white-smoke-color;
}

//Lime Green
.bg-lime-green-color {
  background-color: $lime-green-color;
}
.lime-green-color {
  color: $lime-green-color;
}
.b-lime-green-color {
  border-color: $lime-green-color;
}

//Dodger Blue
.bg-dodger-blue-color {
  background-color: $dodger-blue-color;
}
.dodger-blue-color {
  color: $dodger-blue-color;
}
.b-dodger-blue-color {
  border-color: $dodger-blue-color;
}

// Stardust color
.bg-star-dust-color {
  background-color: $star-dust-color;
}
.star-dust-color {
  color: $star-dust-color;
}
.b-star-dust-color {
  border-color: $star-dust-color;
}

// steel gray color
.bg-steel-gray-color {
  background-color: $steel-gray-color;
}
.steel-gray-color {
  color: $steel-gray-color;
}
.b-steel-gray-color {
  border-color: $steel-gray-color;
}

// napoli color
.bg-napoli-blue-color {
  background-color: $napoli-blue-color;
}
.napoli-blue-color {
  color: $napoli-blue-color;
}
.b-napoli-blue-color {
  border-color: $napoli-blue-color;
}

// midnight color
.bg-midnight-color {
  background-color: $midnight-color;
}
.midnight-color {
  color: $midnight-color;
}
.b-midnight-color {
  border-color: $midnight-color;
}

// midnight color
.bg-middle-gray-color {
  background-color: $middle-gray-color;
}
.middle-gray-color {
  color: $middle-gray-color;
}
.b-middle-gray-color {
  border-color: $middle-gray-color;
}
.star-dust-grey {
  color: $star-dust-grey;
}

.dark-grey {
  color: $dark-grey;
}

// Linear Gradient Background
.bg-orange-gradient {
  background: linear-gradient(
    92.28deg,
    #e97000 29.82%,
    #ff9c40 69.57%,
    #e97001 91.94%
  );
}

.bg-green-offer {
  background-color: #cdffe4;
}

.color-white {
  color: $white-color;
}

.bg-ocean-green {
  background-color: $ocean-green;
}
